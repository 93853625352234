<template>
  <v-tabs centered v-model="tab" :value="tab">
    <template v-for="(rootItem, i) in items">
      <v-tab v-if="rootItem.show" :key="i" :to="rootItem.to">
        <span>{{ rootItem.text }}</span>
      </v-tab>
    </template>
  </v-tabs>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CeurTopMenu",
  data: () => ({
    tab: 1
  }),
  computed: {
    ...mapGetters(["breadcrumbs", "selectedParticipant", "selectedLocale"]),
    items() {
      return [
        {
          text: this.$i18n.translate("FAQ"),
          to: { name: "ceurFaq" },
          show: false
        },
        {
          text: this.$i18n.translate("Track My Rebate"),
          to: { name: "ceurTrack" },
          show: true
        }
      ];
    }
  }
};
</script>
